import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/new_retention_options.png';

const Content = () => {
  return (
    <div>
      <p>
        For everyone who need longer sessions data storage, there is an option now. We&apos;ve
        released 3 month retention. This can be changed in subscription settings.
      </p>
      <ImageModal src={img} alt="Data rentetion" title="Determine how long data will be kept" />
      <p className="mt-3">
        Remember that retention value is set during the moment of session creation. It&apos;s best
        to buy more data history from the start.
      </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'New retention options',
  url: '/blog/new-retention-options/',
  description: 'New 3 months sessions data storage is now available',
  author,
  img,
  date: '2019-04-08',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
